/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ValidationPage = ({ location }: any) => {
    const params = new URLSearchParams(location.search)
    const cid = params.get('cid')
    const [sended, setSended] = useState('')

    return (
        <div style={{ padding: 20 }}>
            <h2 style={{ fontSize: 30, marginLeft: 50 }}>{sended} </h2>
            <button
                style={{ width: 200, fontSize: 30, margin: 50 }}
                onClick={() => {
                    console.log('Clicked')
                    try {
                        fetch(
                            'https://www.google-analytics.com/mp/collect?&api_secret=K1oUmabjQxa79C5JQiKjkQ&measurement_id=G-N3HHP3GME7',
                            {
                                method: 'POST',
                                body: JSON.stringify({
                                    client_id: cid,
                                    non_personalized_ads: false,
                                    events: [
                                        {
                                            name: 'ValidGA4',
                                        },
                                    ],
                                }),
                            }
                        )
                        setSended('OK   ')
                    } catch (e) {
                        console.log('Error')
                        setSended('Error   ')

                        console.log(e)
                    } finally {
                        console.log('end')
                    }
                }}
            >
                Validate GA4
            </button>
        </div>
    )
}

export default ValidationPage
